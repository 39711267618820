import Vue from 'vue'
import { sortCompare, isObject, isEmptyObject } from '@core/utils/utils'
import { nombreEdicion } from '@core/utils/filter'
import { getFiles, postRequest } from '@core/utils/server-request/general'

export default {
  namespaced: true,
  state: {
    sortBy: { text: 'Descendente', value: 'desc' },
    loader: { ediciones: true, showMore: false },
    isVisibleShowMoreBtn: false,
    fullItems: [],
    itemsToShow: [],
  },
  getters: {
    nombreEdicion: state => id => {
      if (Number.isNaN(id) || id === 0) return {}
      return nombreEdicion(state.fullItems.find(item => item.id_archivo === parseInt(id, 10)))
    },
    detalleEdicion: state => id => {
      if (Number.isNaN(id) || id === 0) return {}
      return state.fullItems.find(item => item.id_archivo === parseInt(id, 10))
    },
  },
  mutations: {
    SET_LOADER(state, someLoader) {
      Object.entries(someLoader).forEach(element => {
        const [propiedad, status] = element
        state.loader[propiedad] = status
      })
    },
    CLEAN_ITEM_TO_SHOW(state) {
      state.itemsToShow = []
    },
    ADD_ITEM_TO_SHOW(state, item) {
      state.itemsToShow.push(item)
      if (state.itemsToShow.length === state.fullItems.length) state.isVisibleShowMoreBtn = false
    },
    ADD_ALL_ITEMS(state, items) {
      for (let i = 0; i < items.length; i += 1) {
        /* eslint-disable no-param-reassign */
        items[i].id_archivo = parseInt(items[i].id_archivo, 10)
        items[i].showLoaderImg = true
        /* eslint-enable no-param-reassign */
      }
      state.fullItems = items || []
    },
    IS_VISIBLE_SHOW_MORE(state, status) {
      state.isVisibleShowMoreBtn = status
    },
    CHANGE_ORDER(state, sortBy) {
      state.sortBy = sortBy
      const sortedData = state.itemsToShow.sort(sortCompare('id_archivo'))
      if (state.sortBy.value === 'desc') sortedData.reverse()
      state.itemsToShow = sortedData
    },
  },
  actions: {
    fetchItems({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const { originConfig } = Vue.config

        const data = new FormData()
        data.append('origen', originConfig.origen)

        if (isObject(originConfig) && !isEmptyObject(originConfig)) {
          commit('SET_LOADER', { ediciones: true })
          postRequest('func-biblioteca.php', data)
            .then(response => {
              if (response.data.status === 200) {
                commit('CLEAN_ITEM_TO_SHOW')
                commit('ADD_ALL_ITEMS', response.data.ediciones)
                commit('IS_VISIBLE_SHOW_MORE', true)
                dispatch('showMoreItems')
                commit('SET_LOADER', { ediciones: false })
                resolve(response.data)
              } else {
                commit('SET_LOADER', { ediciones: false })
                reject(response.data.message)
              }
            })
            .catch(err => {
              commit('SET_LOADER', { ediciones: false })
              reject(err)
            })
          // eslint-disable-next-line prefer-promise-reject-errors
        } else reject('Error getting request config')
      })
    },
    showMoreItems({ state, commit }) {
      commit('SET_LOADER', { showMore: true })
      const sizeToShow = state.itemsToShow.length
      const sizeFull = state.fullItems.length

      if (sizeToShow <= sizeFull) {
        for (let i = sizeToShow; i < sizeToShow + 8; i += 1) if (i < sizeFull) commit('ADD_ITEM_TO_SHOW', state.fullItems[i])
      }
      commit('SET_LOADER', { showMore: false })
      commit('CHANGE_ORDER', state.sortBy)
    },
    itemsToShow({ state }) {
      const sortedData = state.itemsToShow.sort(sortCompare('id_archivo'))
      if (state.orderBy === 'desc') sortedData.reverse()
      state.itemsToShow = sortedData
    },
    fetchImagesToPreview(_, path) {
      return new Promise((resolve, reject) => {
        const data = {
          sl: 0,
          path,
          origin: 'all',
        }

        getFiles('delivery', data)
          .then(response => {
            if (response.status === 200) resolve(response.data)
            else reject(response.data.message)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
  },
}

import axiosIns from '@axios'
import Vue from 'vue'

export function getRequest(api, params, prodHost = false) {
  const { originConfig } = Vue.config
  const production = 'https://www.indetec.gob.mx'

  return axiosIns({
    url: `${(prodHost) ? production : originConfig.host}/${api}`,
    method: 'GET',
    params,
  })
}

export function postRequest(api, data, prodHost = false) {
  const { originConfig } = Vue.config
  const production = 'https://www.indetec.gob.mx'

  return axiosIns({
    url: `${(prodHost) ? production : originConfig.host}/${api}`,
    method: 'POST',
    data,
  })
}

export function getFiles(api, params) {
  return axiosIns({
    url: `https://archivos.indetec.gob.mx/${api}?getFiles`,
    method: 'GET',
    params,
  })
}
